<template>
  <div class="ai-authentication">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.ai-authentication {
  height: 100%;
}
</style>